import { FC, useEffect, useState } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { UiModal } from '@shared/ui/ui-modal';
import { ModalContentGrid } from './styles';
import { UiButton } from '@shared/ui/ui-button';
import { StyledCheckbox } from '@shared/ui/custom-checkbox/styled';

export interface TableColumnType {
  label: string;
  key: string;
  isChecked: boolean;
}

type Props = {
  columns: TableColumnType[];
  onConfirm: (columns: TableColumnType[]) => void;
};

export const VisibleColumnsModal = NiceModal.create(
  ({ columns, onConfirm }: Props) => {
    const [cols, setCols] = useState(columns);

    useEffect(() => {
      setCols(columns);
    }, [columns]);

    const modal = useModal();

    const onChange = (e: CheckboxChangeEvent) =>
      setCols((cols) =>
        cols.map((el) =>
          el.key === e.target.name ? { ...el, isChecked: !el.isChecked } : el
        )
      );

    return (
      <UiModal isOpen={modal.visible} onClose={modal.hide}>
        <UiModal.Header onClose={modal.hide}>Фильтры</UiModal.Header>
        <UiModal.Body>
          <ModalContentGrid>
            {cols.map(({ label, key, isChecked }) => (
              <StyledCheckbox
                name={key}
                checked={isChecked}
                onChange={onChange}
                key={key}
              >
                {label}
              </StyledCheckbox>
            ))}
          </ModalContentGrid>
        </UiModal.Body>
        <UiModal.Footer>
          <UiButton
            onClick={() => {
              onConfirm(cols);
              modal.hide();
            }}
            variant="primary"
          >
            Применить
          </UiButton>
        </UiModal.Footer>
      </UiModal>
    );
  }
);
