import { StatusTracker } from '@shared/api/services/tracker/api';
import { FormSchema, FormSchemaType } from '../../consts/schema';
import { useData } from '../../hooks/use-data';
import { useForm, Controller, type FieldValues } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAppSelector } from '@app/hooks';
import {
  selectTrackersFilters,
  setFilters,
} from '@shared/redux/slices/tracker/filters';
import { resetPageByFiltering } from '@shared/utils/table/reset-page-by-filtering';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormButtonsContainer, FormLayout, StyledAntdSelect } from './styles';
import { UiButton } from '@shared/ui/ui-button';
import {
  FieldErrorContainer,
  FormColumn,
  FormItemContainer,
  FormItemLabel,
} from '@shared/ui/form/styles';
import { CustomSelectMultiple } from '@shared/ui/custom-select-new/components/multiple-select';
import { StyledAntdSelectOld } from '../../../../../old/custom-select/styles';
import { CustomedRangePicker } from '@shared/ui/ui-customed-range-picker';
import dayjs from 'dayjs';
import { ConfigProvider } from 'antd';
import locale from 'antd/locale/ru_RU';
import theme from 'antd/es/theme';

type Props = {
  closeModal: () => void;
};

export function FiltersForm({ closeModal }: Props) {
  const filters = useAppSelector(selectTrackersFilters);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const {
    isLoading,
    error,
    trackersModels,
    firmwaresData,
    trackersImeisData,
    parks: parksData,
  } = useData();

  const {
    formState: { errors, isValid },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: filters,
  });

  const statuses = watch('statuses');
  const modelIds = watch('modelIds');
  const firmware = watch('firmware');
  const imei = watch('imei');
  const range = watch('range');
  const parks = watch('parks');

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <>
        <div>Ошибка: не удалось загрузить фильтры</div>
        <br />
        <div>{JSON.stringify(error)}</div>
        <br />
      </>
    );
  }

  const onSubmit = async (data: FieldValues) => {
    resetPageByFiltering(searchParams, setSearchParams);
    dispatch(setFilters(data));
    closeModal();
  };

  const handleReset = () => {
    setValue('modelIds', []);
    setValue('statuses', []);
    setValue('firmware', []);
    setValue('imei', undefined);
    setValue('range', null);
    setValue('parks', []);
  };

  const modelsOptions = trackersModels!;
  const firmwaresOptions = firmwaresData!;
  const trackerStatusOptions: Array<{ label: string; value: StatusTracker }> = [
    { label: 'Online', value: 'ONLINE' },
    { label: 'Offline', value: 'OFFLINE' },
  ];

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="password"
      >
        <FormLayout>
          <FormColumn>
            <FormItemContainer>
              <FormItemLabel>Модель</FormItemLabel>
              <Controller
                name="modelIds"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <CustomSelectMultiple
                      {...field}
                      value={modelIds}
                      options={modelsOptions.map((el) => ({
                        value: el.id,
                        label: el.name,
                      }))}
                      allowClear
                      onClear={() => setValue('modelIds', [])}
                    />
                  );
                }}
              />
              <FieldErrorContainer>
                {errors?.modelIds && <p>{errors.modelIds.message}</p>}
              </FieldErrorContainer>
            </FormItemContainer>
            <FormItemContainer>
              <FormItemLabel>Прошивка</FormItemLabel>
              <Controller
                name="firmware"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <CustomSelectMultiple
                      {...field}
                      value={firmware}
                      options={firmwaresOptions
                        .map((el) => ({
                          value: el,
                          label: el,
                        }))
                        // @ts-check
                        .sort((a, b) => a.value - b.value)}
                      allowClear
                      onClear={() => setValue('firmware', [])}
                    />
                  );
                }}
              />
              <FieldErrorContainer>
                {errors?.firmware && <p>{errors.firmware.message}</p>}
              </FieldErrorContainer>
            </FormItemContainer>
            <FormItemContainer>
              <FormItemLabel>Статус трекера</FormItemLabel>
              <Controller
                name="statuses"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <CustomSelectMultiple
                      {...field}
                      value={statuses}
                      options={trackerStatusOptions.map(({ label, value }) => ({
                        value,
                        label,
                      }))}
                      allowClear
                      onClear={() => setValue('statuses', [])}
                    />
                  );
                }}
              />
              <FieldErrorContainer>
                {errors?.statuses && <p>{errors.statuses.message}</p>}
              </FieldErrorContainer>
            </FormItemContainer>
          </FormColumn>
          <FormColumn>
            <FormItemContainer>
              <FormItemLabel>ID трекера (imei)</FormItemLabel>
              <Controller
                name="imei"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <StyledAntdSelect
                      {...field}
                      showSearch
                      placeholder="Введите номер трекера"
                      value={imei}
                      options={trackersImeisData.map((el) => ({
                        value: el,
                        label: el,
                      }))}
                      allowClear
                      // onClear={resetImei}
                    />
                  );
                }}
              />
              <FieldErrorContainer>
                {errors?.imei && <p>{errors.imei.message}</p>}
              </FieldErrorContainer>
            </FormItemContainer>
            <FormItemContainer>
              <FormItemLabel>Был в сети</FormItemLabel>
              <Controller
                name="range"
                control={control}
                render={({ field, fieldState }) => (
                  // @ts-ignore
                  <CustomedRangePicker
                    {...field}
                    format="HH:mm, YYYY.MM.DD"
                    range={range}
                    //  onChange={(value, dateString) => {
                    //    // @ts-ignore
                    //    setValue('range', value);
                    //  }}
                    onOk={(value) => {
                      // @ts-ignore
                      setValue('range', value);
                    }}
                    // @ts-ignore
                    maxDate={dayjs()}
                    allowClear
                    showTime={{ format: 'HH:mm' }}
                  />
                )}
              />
              <FieldErrorContainer>
                {errors?.range && <p>{errors.range.message}</p>}
              </FieldErrorContainer>
            </FormItemContainer>
            <FormItemContainer>
              <FormItemLabel>Парк</FormItemLabel>
              <Controller
                name="parks"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <CustomSelectMultiple
                      {...field}
                      value={parks}
                      options={parksData.map((el) => ({
                        value: el,
                        label: el,
                      }))}
                      allowClear
                      onClear={() => setValue('parks', [])}
                    />
                  );
                }}
              />
              <FieldErrorContainer>
                {errors?.parks && <p>{errors.parks.message}</p>}
              </FieldErrorContainer>
            </FormItemContainer>
          </FormColumn>
        </FormLayout>

        <FormButtonsContainer>
          <UiButton variant="outline" onClick={handleReset} type="button">
            Сбросить
          </UiButton>
          <UiButton variant="primary" type="submit">
            Применить
          </UiButton>
        </FormButtonsContainer>
      </form>
    </ConfigProvider>
  );
}
