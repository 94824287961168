import { useEffect, useState } from 'react';

import { UiInput } from '@shared/ui/ui-input';

import { ShowRowsControlContainer } from './styles';

type Props = {
  size: number;
  rowsCount: number;
  handleEnterPress: (val: number) => void;
};

export function ShowRowsControl({ rowsCount, handleEnterPress, size }: Props) {
  const [value, setValue] = useState(String(size));

  useEffect(() => {
    setValue(String(size));
  }, [size]);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) > 0 || e.target.value === '') {
      setValue(e.target.value);
    }
  };

  return (
    <ShowRowsControlContainer>
      <span>Показывать строк</span>
      <UiInput
        inputSize="sm"
        value={value}
        onChange={handleValueChange}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            const val = Number((e.target as HTMLInputElement).value);

            if (val > 0) {
              handleEnterPress(val);
            }
          }
        }}
      />
      <span>из</span>
      {rowsCount}
    </ShowRowsControlContainer>
  );
}
