import { QUERY_PARAM } from '@shared/consts/server';
import { Cell, Header, Row, Table } from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';

import { TableControls } from '../../controls';
import { useSortingChange } from '../../hooks/use-sorting-change';

import { TableLayout } from '../table-layout';

type Props = {
  table: Table<any>;
  loading: boolean;
  handleRowDoubleClick?: (row: Row<any>) => void;
  rowsCount: number | undefined;
  defaultPageSize: number;
};

export function ServerTableLayout({
  table,
  loading,
  handleRowDoubleClick,
  defaultPageSize,
  rowsCount,
}: Props) {
  const onSortingChange = useSortingChange();

  //
  const [searchParams, setSearchParams] = useSearchParams();

  const ordering = searchParams.get('ordering');
  const type = searchParams.get('type');
  const page = searchParams.get('page');
  const limit = searchParams.get(QUERY_PARAM.limit);
  //

  const getTdClassName = (cell: Cell<any, unknown>) => {
    return ordering === cell.column.id && type !== null ? 'sorted' : '';
  };

  console.log('limit', limit);
  console.log('defaultPageSize', defaultPageSize);

  return (
    <TableLayout
      table={table}
      loading={loading}
      getTdClassName={getTdClassName}
      // handleSortChange={handleSortChange}
      handleRowDoubleClick={handleRowDoubleClick}
      tableControls={
        <TableControls
          table={table}
          rowsCount={rowsCount}
          pageSize={limit ? Number(limit) : defaultPageSize}
        />
      }
    />
  );
}
