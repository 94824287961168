import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  getCoreRowModel,
  useReactTable,
  PaginationState,
  getPaginationRowModel,
  RowSelectionState,
} from '@tanstack/react-table';

import { VehiclesTelemetryData } from '../consts/types';
import { QUERY_PARAM } from '@shared/consts/server';
import { useVehicleTelemetryColumns } from '../hooks/use-vehicle-telemetry-columns';
import { ServerTableLayout } from '@shared/ui/table/layout';
import { usePaginationState, useSortingState } from '@shared/ui/table/hooks';

type Props = {
  vehiclesData: VehiclesTelemetryData;
  count: number;
  defaultPageSize: number;
  loading: boolean;
};

export const VehiclesTelemetryTableInstanse: FC<Props> = ({
  vehiclesData,
  count,
  defaultPageSize,
  loading,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const size = searchParams.get(QUERY_PARAM.limit);
  const page = searchParams.get(QUERY_PARAM.page);
  const sortedField = searchParams.get(QUERY_PARAM.ordering);
  const sortedBy = searchParams.get(QUERY_PARAM.type);

  const columns = useVehicleTelemetryColumns();

  const sorting = useSortingState();
  const pagination = usePaginationState();

  useEffect(() => {
    setSearchParams({});
  }, []);

  const sortedData = useMemo(() => {
    const clonedVehiclesData = JSON.parse(
      JSON.stringify(vehiclesData)
    ) as VehiclesTelemetryData;

    if (sortedBy === 'asc' && sortedField !== null) {
      if (sortedField === 'park_name') {
        return clonedVehiclesData.sort((a, b) => {
          const prevName = a.park_name ?? '';
          const nextName = b.park_name ?? '';
          return prevName.localeCompare(nextName, 'ru-RU');
        });
      }
      if (sortedField === 'time') {
        return clonedVehiclesData.sort((a, b) => {
          if (a.time === undefined || a.time === null) return 1; // null всегда внизу
          if (b.time === undefined || b.time === null) return -1; // null всегда внизу
          //@ts-ignore
          return new Date(a.time) - new Date(b.time);
        });
      }

      if (sortedField === 'last_charge') {
        return clonedVehiclesData.sort((a, b) => {
          if (a.last_charge === undefined || a.last_charge === null) return 1; // null всегда внизу
          if (b.last_charge === undefined || b.last_charge === null) return -1; // null всегда внизу
          //@ts-ignore
          return new Date(a.last_charge) - new Date(b.last_charge);
        });
      }
      return clonedVehiclesData.sort((a, b) => a[sortedField] - b[sortedField]);
    }

    if (sortedBy === 'desc' && sortedField !== null) {
      if (sortedField === 'park_name') {
        return clonedVehiclesData.sort((a, b) => {
          const prevName = a.park_name ?? '';
          const nextName = b.park_name ?? '';
          return nextName.localeCompare(prevName, 'ru-RU');
        });
      }
      if (sortedField === 'time') {
        return clonedVehiclesData.sort((a, b) => {
          if (a.time === undefined || a.time === null) return 1; // null всегда внизу
          if (b.time === undefined || b.time === null) return -1; // null всегда внизу
          //@ts-ignore
          return new Date(b.time) - new Date(a.time);
        });
      }

      if (sortedField === 'last_charge') {
        return clonedVehiclesData.sort((a, b) => {
          if (a.last_charge === undefined || a.last_charge === null) return 1; // null всегда внизу
          if (b.last_charge === undefined || b.last_charge === null) return -1; // null всегда внизу
          //@ts-ignore
          return new Date(b.last_charge) - new Date(a.last_charge);
        });
      }
      return clonedVehiclesData.sort((a, b) => b[sortedField] - a[sortedField]);
    }

    return clonedVehiclesData;
  }, [vehiclesData, sortedField, sortedBy]);

  const paginatedData = useMemo(() => {
    const currentPage = Number(page ?? 1);
    const currentSize = Number(size ?? defaultPageSize);

    const startIdx = (currentPage - 1) * currentSize;
    const endIdx = startIdx + currentSize;
    return sortedData.slice(startIdx, endIdx);
  }, [sortedData, size, page]);

  const table = useReactTable({
    data: paginatedData,
    columns,
    enableRowSelection: true,
    state: {
      sorting,
      pagination,
      rowSelection,
    },
    getRowId: (row) => row.imei,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: count
      ? Math.ceil(count / (size ? Number(size) : defaultPageSize))
      : undefined,
  });

  return (
    <ServerTableLayout
      loading={loading}
      table={table}
      rowsCount={count}
      handleRowDoubleClick={(row) => {
        setRowSelection({ [row.id]: !row.getIsSelected() });
      }}
      defaultPageSize={defaultPageSize}
    />
  );
};
