import { baseApi as api } from '../../../base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getElectricbusesApiElectricbusesGet: build.query<
      GetElectricbusesApiElectricbusesGetApiResponse,
      GetElectricbusesApiElectricbusesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/electricbuses`,
        params: { group_ids: queryArg },
      }),
    }),
    createElectricbusApiElectricbusesPost: build.mutation<
      CreateElectricbusApiElectricbusesPostApiResponse,
      CreateElectricbusApiElectricbusesPostApiArg
    >({
      query: (queryArg) => ({
        url: `/api/electricbuses`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    updateElectricbusApiElectricbusesPatch: build.mutation<
      UpdateElectricbusApiElectricbusesPatchApiResponse,
      UpdateElectricbusApiElectricbusesPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/api/electricbuses`,
        method: 'PATCH',
        body: queryArg,
      }),
    }),
    deleteElectricbusApiElectricbusesEvIdDelete: build.mutation<
      DeleteElectricbusApiElectricbusesEvIdDeleteApiResponse,
      DeleteElectricbusApiElectricbusesEvIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/electricbuses/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    updateTrackerSrvElectricbusesPatch: build.mutation<
      UpdateTrackerSrvElectricbusesPatchApiResponse,
      UpdateTrackerSrvElectricbusesPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/srv/electricbuses`,
        method: 'PATCH',
        body: queryArg,
      }),
    }),
    getElectricbusByGroupIdSrvElectricbusesGroupGroupIdGet: build.query<
      GetElectricbusByGroupIdSrvElectricbusesGroupGroupIdGetApiResponse,
      GetElectricbusByGroupIdSrvElectricbusesGroupGroupIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/srv/electricbuses/group/${queryArg}` }),
    }),
    getElectricbusByIdSrvElectricbusesEvIdGet: build.query<
      GetElectricbusByIdSrvElectricbusesEvIdGetApiResponse,
      GetElectricbusByIdSrvElectricbusesEvIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/srv/electricbuses/${queryArg.evId}`,
        params: { is_with_tracker: queryArg.isWithTracker },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetElectricbusesApiElectricbusesGetApiResponse =
  /** status 200 Successful Response */ ElectricBusOutput[];
export type GetElectricbusesApiElectricbusesGetApiArg =
  /** List IDs group */ string;
export type CreateElectricbusApiElectricbusesPostApiResponse =
  /** status 201 Successful Response */ ElectricBusOutput;
export type CreateElectricbusApiElectricbusesPostApiArg = ElectricBusCreate;
export type UpdateElectricbusApiElectricbusesPatchApiResponse =
  /** status 200 Successful Response */ ElectricBusOutput;
export type UpdateElectricbusApiElectricbusesPatchApiArg = ElectricBusUpdate;
export type DeleteElectricbusApiElectricbusesEvIdDeleteApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteElectricbusApiElectricbusesEvIdDeleteApiArg = string;
export type UpdateTrackerSrvElectricbusesPatchApiResponse =
  /** status 200 Successful Response */ ElectricBusOutput;
export type UpdateTrackerSrvElectricbusesPatchApiArg = ElectricBusUpdate;
export type GetElectricbusByGroupIdSrvElectricbusesGroupGroupIdGetApiResponse =
  /** status 200 Successful Response */ ElectricBusOutput[] | null;
export type GetElectricbusByGroupIdSrvElectricbusesGroupGroupIdGetApiArg =
  number;
export type GetElectricbusByIdSrvElectricbusesEvIdGetApiResponse =
  /** status 200 Successful Response */ ElectricBusWithTrackerOutput;
export type GetElectricbusByIdSrvElectricbusesEvIdGetApiArg = {
  evId: string;
  isWithTracker?: boolean;
};
export type ElectricBusOutput = {
  id: string;
  grn: string;
  garage_number: number;
  type_id: number;
  group_id: number;
  model_id: number;
  manufacture_id: number;
  status_id: number;
  last_charge_time?: string | null;
  mileage?: number | null;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type ElectricBusCreate = {
  grn: string;
  garage_number: number;
  type_id?: number;
  model_id: number;
  group_id: number;
  manufacture_id: number;
  status_id?: number | null;
};
export type ElectricBusUpdate = {
  id: string;
  grn?: string | null;
  garage_number?: number | null;
  type_id?: number | null;
  model_id?: number | null;
  group_id?: number | null;
  manufacture_id?: number | null;
  status_id?: number | null;
  last_charge_time?: string | null;
  mileage?: number | null;
};
export type StatusTracker = 'ONLINE' | 'OFFLINE';
export type TrackerOutput = {
  id: string;
  imei: string;
  status: StatusTracker;
  firmware?: string | null;
  ev_id: string;
  ev_type_id: number;
  phone_number?: string | null;
  last_online?: string | null;
};
export type ElectricBusWithTrackerOutput = {
  soc?: number | null;
  soh?: number | null;
  id: string;
  grn: string;
  garage_number: number;
  type_id: number;
  group_id: number;
  model_id: number;
  manufacture_id: number;
  status_id: number;
  last_charge_time?: string | null;
  mileage?: number | null;
  tracker?: TrackerOutput | null;
};
export const {
  useGetElectricbusesApiElectricbusesGetQuery,
  useCreateElectricbusApiElectricbusesPostMutation,
  useUpdateElectricbusApiElectricbusesPatchMutation,
  useDeleteElectricbusApiElectricbusesEvIdDeleteMutation,
  useUpdateTrackerSrvElectricbusesPatchMutation,
  useGetElectricbusByGroupIdSrvElectricbusesGroupGroupIdGetQuery,
  useGetElectricbusByIdSrvElectricbusesEvIdGetQuery,
} = injectedRtkApi;
