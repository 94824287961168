import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export const GlobalStyles = createGlobalStyle`
  ${reset}

  html {
    box-sizing: border-box;
  }

  *,
  *::after,
  *::before {
    box-sizing: inherit;
  }

  html, 
  body {
    height: 100%;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button {
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  body {
    font-family: 'Onest', sans-serif;
    font-size: 14px;
    line-height: 17px;
    background-color: #32353F;
    color: #F7FAFF;
    //

  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #A3AEC4;
    border-radius: 5px;
  }

  // Virtualized Select List

  & .rc-virtual-list-holder-inner {
    gap: 0px;
    overflow-y: auto;
  }

  & .ant-select-dropdown {
    background-color: #32353f;
    border-radius: 0 0 12px 12px;
    border-width: 0 2px 2px 2px;
    border-style: solid;
    border-color: #09FBA8;
    padding: 12px;

    // CHECK
    // animation-duration: auto;
    // 

    & div.ant-select-item.ant-select-item-option {
      background-color: #32353f;
      padding: 0;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      color: #F7FAFF;
      min-height: unset;

      &.ant-select-item-option-selected {
        font-weight: 600;
        color: #09FBA8;
      }
    }

    // Для кнопки Select/Deselect All
    & .ant-select-item.ant-select-item-group {
      background-color: #32353f;
      padding: 0;

      min-height: unset;
    }
  }

  // Tooltip
  & .ant-tooltip .ant-tooltip-inner {
    padding: 16px;
    background-color: #09a56f;
    max-width: 528px;
  }

  & .ant-tooltip .ant-tooltip-arrow:before {
    background-color: #09a56f;
  }


  // Spin
  & .ant-spin-nested-loading .ant-spin-container.ant-spin-blur::after {
    opacity: 0;
  }

  // RangePicker

  & .ant-picker-cell-range-start .ant-picker-cell-inner {
    background-color: #09a56f !important;
  }
 
  & .ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: #09a56f !important;
  }
 
  & .ant-picker-cell-in-range  {
 //   background-color: #015135 !important;

  }

  & .ant-btn {
    background-color: #09FBA8;

    &:hover {
      background-color: #09FBA8 !important;
    }
  }

`;
