import dayjs, { Dayjs } from 'dayjs';
import { z } from 'zod';

const dayjsType = z.custom<Dayjs>((data) => dayjs.isDayjs(data), {
  message: 'Expected an instance of dayjs',
});

export const FormSchema = z.object({
  statuses: z.array(z.string()),
  firmware: z.array(z.string()),
  modelIds: z.array(z.number()),
  imei: z.string().optional(),
  parks: z.array(z.string()),
  range: z
    .array(dayjsType)
    .length(2, { message: 'Нужно ввести две даты' })
    .nullable(),
});

export type FormSchemaType = z.infer<typeof FormSchema>;
