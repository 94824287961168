import { Collapse } from 'antd';
import styled from 'styled-components';

const dynamicColor = (props: { percent: number }) => {
  const { percent } = props;

  // No information - какой кейс ?

  if (percent <= 30) {
    return '#FE4D4D';
  } else if (percent >= 31 && percent <= 70) {
    return '#FF9A24';
  } else {
    return '#1FC48B';
  }
};

export const StyledContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 200px);
  background-color: transparent;
  border: 1px solid #5d5d5d;
  padding-bottom: 10px;
  border-radius: 8px;
  min-width: 998px;
  z-index: 10000;
`;

export const VehicleInfoHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: inherit;
`;

export const VehicleParams = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding: 5px 20px;
  align-items: center;
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  margin-left: auto;
  align-self: start;
  color: #a3aec4;

  &:hover {
    color: #f7faff;
  }
`;

export const DriverInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #f7faff;

  align-items: center;
`;

export const DriverInfoKey = styled.div`
  padding: 8px 44px 8px 15px;
  font-weight: 600;

  @media screen and (max-width: 1440px) {
    padding: 5px 18px 5px 0;
  }
`;

export const DriverInfoValue = styled.div`
  padding: 8px 40px 8px 0;

  @media screen and (max-width: 1440px) {
    padding: 5px 16px 5px 0;
  }
`;

export const RowBorder = styled.div`
  border-top: 1px solid rgba(31, 196, 139, 0.4);
  grid-column: 1 / 3;
`;

export const SohValue = styled.span`
  color: ${dynamicColor};
`;

export const VehicleExtraInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  color: #f7faff;
`;

export const VehicleExtraInfoParam = styled.div`
  display: flex;
  justify-content: center;
  background: linear-gradient(
    112.1deg,
    rgb(9, 5, 61) 11.4%,
    rgb(0, 0, 0) 70.2%
  );
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  // padding: 50px;
  align-items: center;

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  color: #f7faff;

  height: 100px;
  width: 21%;

  @media screen and (max-width: 2333px) {
    height: 100px;
  }

  @media screen and (max-width: 1920px) {
    height: 85px;
  }

  @media screen and (max-width: 1720px) {
    height: 65px;
  }
`;

export const OutputInfoParam = styled(VehicleExtraInfoParam)`
  width: 18%;
`;

export const VehicleExtraInfoParamTitle = styled.span`
  color: '#ffffff';
  font-size: 30px;
  margin-right: 10px;

  @media screen and (max-width: 2333px) {
    font-size: 25px;
  }

  @media screen and (max-width: 1920px) {
    font-size: 22px;
  }

  @media screen and (max-width: 1720px) {
    font-size: 17px;
  }
`;

export const VehicleExtraInfoParamValue = styled(VehicleExtraInfoParamTitle)`
  margin-right: 0px;
`;

export const VehicleSoc = styled(VehicleExtraInfoParamValue)<{
  percent: number;
}>`
  color: ${dynamicColor};
`;

export const BusRouteTitle = styled.span`
  color: '#ffffff';
  font-size: 28px;
  line-height: 38px;
`;

export const BusRouteValue = styled(VehicleExtraInfoParamValue)`
  color: #b3abab;
`;

export const FuelDistanceEndurance = styled(VehicleSoc)``;

export const BusRouteInfoWrapper = styled.div`
  padding: 20px;
`;

export const StyledCollapse = styled(Collapse)`
  border: none !important;
  background: transparent;

  & .ant-collapse-item {
    & .ant-collapse-header.ant-collapse-header-collapsible-only {
      border-radius: 16px;
      align-items: center;

      & .ant-collapse-expand-icon {
        padding: 0;
        height: unset;

        & svg {
          width: 16px;

          @media screen and (max-width: 1920px) {
            width: 14px;
          }

          @media screen and (max-width: 1440px) {
            width: 11px;
          }
        }
      }
    }

    & .ant-collapse-content {
      margin-top: 4px;
    }

    & .ant-collapse-content {
      background-color: transparent !important;
      border-top: none !important;

      & .ant-collapse-content-box {
        padding: 0 0 0px 0px;
      }
    }

    & .ant-collapse-content.ant-collapse-content-active {
      & .ant-collapse-content-box {
        padding: 0 0 0px 0;
      }
    }
  }

  & .ant-collapse-item.ant-collapse-item-active {
    & .ant-collapse-header.ant-collapse-header-collapsible-only {
      background-color: rgba(31, 196, 139, 0.4);
      color: #09fba8;

      & .ant-collapse-expand-icon {
        transform: rotate(180deg);

        & svg {
          width: 16px;

          @media screen and (max-width: 1920px) {
            width: 14px;
          }

          @media screen and (max-width: 1440px) {
            width: 11px;
          }
        }
      }
    }
  }

  & .ant-collapse-item {
    & .ant-collapse-header {
      padding: 6px 16px;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      color: #f7faff;
      border-radius: 16px;
    }
  }
`;
