import { DatePicker } from 'antd';
import styled from 'styled-components';
const { RangePicker } = DatePicker;

export const CustomedRangePicker = styled(RangePicker)`
  background-color: transparent;
  height: 41px;
  border-radius: 12px;
  border: 2px solid #666e7e !important;

  &:hover {
    background-color: transparent;
    border-color: #ffffffc7 !important;
  }

  &:focus {
    background-color: transparent;
    border-color: #ffffffc7 !important;
  }

  &.ant-picker-focused {
    background-color: transparent;
    border-color: rgb(9, 251, 168) !important;
  }

  & .ant-picker-active-bar {
    background-color: white;
    border-color: #ffffffc7 !important;
  }
`;
