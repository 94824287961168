import { TrackerOutput } from '@shared/api/services/tracker/api';
import { QUERY_PARAM } from '@shared/consts/server';
import { usePaginationState, useSortingState } from '@shared/ui/table/hooks';
import { ServerTableLayout } from '@shared/ui/table/layout';
import {
  ColumnDef,
  getCoreRowModel,
  RowSelectionState,
  useReactTable,
} from '@tanstack/react-table';
import { FC, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useColumns } from '../../hooks/use-columns';
import { GetGroupsApiTrackersModelsGetApiResponse } from '@shared/api/services/common/api';
import { useSortTrackersData } from '../../hooks/use-sort-trackers-data';
import { TrackerTableType } from '@entities/tracker/consts/types';

type Props = {
  count: number | undefined;
  fetching: boolean;
  tableData: TrackerTableType[];
  models?: GetGroupsApiTrackersModelsGetApiResponse;
  rowSelection: RowSelectionState;
  defaultPageSize: number;
  globalTableData: TrackerTableType[];
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
};

export const TrackersTableInstance: FC<Props> = ({
  count,
  fetching,
  tableData,
  models,
  rowSelection,
  defaultPageSize,
  globalTableData,
  setRowSelection,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const columns: ColumnDef<TrackerTableType, any>[] = useColumns({
    models,
    globalTableData,
    rowSelection,
    setRowSelection,
  });

  const page = searchParams.get(QUERY_PARAM.page);
  const size = searchParams.get(QUERY_PARAM.limit);
  const sortedField = searchParams.get(QUERY_PARAM.ordering);
  const sortedBy = searchParams.get(QUERY_PARAM.type);
  const sorting = useSortingState();
  const pagination = usePaginationState();

  /*
    sorted data
  */

  const sortedData = useSortTrackersData({ tableData, sortedBy, sortedField });

  const paginatedData = useMemo(() => {
    const currentPage = Number(page ?? 1);
    const currentSize = Number(size ?? defaultPageSize);

    const startIdx = (currentPage - 1) * currentSize;
    const endIdx = startIdx + currentSize;
    return sortedData.slice(startIdx, endIdx);
  }, [sortedData, size, page]);

  const table = useReactTable({
    data: paginatedData,
    columns,
    state: {
      sorting,
      pagination,
      rowSelection,
    },
    getRowId: (row) => row.imei,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    manualPagination: true,
    pageCount: count
      ? Math.ceil(count / (size ? Number(size) : defaultPageSize))
      : undefined,
  });

  return (
    <ServerTableLayout
      loading={fetching}
      table={table}
      rowsCount={count}
      defaultPageSize={defaultPageSize}
    />
  );
};
