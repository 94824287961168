import { baseApi as api } from '../../../base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getHotStorageApiHotStorageGet: build.query<
      GetHotStorageApiHotStorageGetApiResponse,
      GetHotStorageApiHotStorageGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/hot_storage`,
        params: { park_ids: queryArg },
      }),
    }),
    getTelemetriesReportApiReportGet: build.query<
      GetTelemetriesReportApiReportGetApiResponse,
      GetTelemetriesReportApiReportGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report`,
        params: {
          imei: queryArg.imei,
          date_from: queryArg.dateFrom,
          date_to: queryArg.dateTo,
        },
      }),
    }),
    getTelemetryByFilterSrvTelemetriesGet: build.query<
      GetTelemetryByFilterSrvTelemetriesGetApiResponse,
      GetTelemetryByFilterSrvTelemetriesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/srv/telemetries`,
        params: {
          soc_from: queryArg.socFrom,
          soc_to: queryArg.socTo,
          soh_from: queryArg.sohFrom,
          soh_to: queryArg.sohTo,
          power_reserve_from: queryArg.powerReserveFrom,
          power_reserve_to: queryArg.powerReserveTo,
          is_with_tracker: queryArg.isWithTracker,
        },
      }),
    }),
    getTelemetryByFilterSrvTelemetriesImeiGet: build.query<
      GetTelemetryByFilterSrvTelemetriesImeiGetApiResponse,
      GetTelemetryByFilterSrvTelemetriesImeiGetApiArg
    >({
      query: (queryArg) => ({ url: `/srv/telemetries/${queryArg}` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetHotStorageApiHotStorageGetApiResponse =
  /** status 200 Successful Response */ TelemetryRedisModel[];
export type GetHotStorageApiHotStorageGetApiArg = string;
export type GetTelemetriesReportApiReportGetApiResponse =
  /** status 200 Successful Response */ any;
export type GetTelemetriesReportApiReportGetApiArg = {
  imei: string;
  dateFrom: string;
  dateTo: string;
};
export type GetTelemetryByFilterSrvTelemetriesGetApiResponse =
  /** status 200 Successful Response */
    | (TelemetryWithTrackerOutput | TelemetryBaseOutput)[]
    | null;
export type GetTelemetryByFilterSrvTelemetriesGetApiArg = {
  socFrom?: number;
  socTo?: number;
  sohFrom?: number;
  sohTo?: number;
  powerReserveFrom?: number;
  powerReserveTo?: number;
  isWithTracker?: boolean;
};
export type GetTelemetryByFilterSrvTelemetriesImeiGetApiResponse =
  /** status 200 Successful Response */ TelemetryBaseOutput;
export type GetTelemetryByFilterSrvTelemetriesImeiGetApiArg = string;
export type BatteryTelemetryErrorRedisModel = {
  pk?: string | null;
  code_error: number;
  text_error: string;
};
export type BatteryTelemetryRedisModel = {
  pk?: string | null;
  number_battery: number;
  min_voltage_V: number;
  max_voltage_V: number;
  avg_voltage_V: number;
  min_temperature_C: number;
  max_temperature_C: number;
  coolant_temp_C: number;
  battery_main_contactor: number;
  battery_state: number;
  firmware_bms: number | null;
  errors?: BatteryTelemetryErrorRedisModel[];
};
export type TelemetryRedisModel = {
  pk?: string | null;
  time: string;
  park_id: number;
  latitude: number;
  pre_latitude?: number | null;
  longitude: number;
  pre_longitude?: number | null;
  tracker_imei: string;
  speed: number;
  soh: number;
  soc: number;
  power_reserve: number | null;
  charge_current_A: number | null;
  discharge_current_A: number | null;
  permissible_charge_current: number | null;
  permissible_discharge_current: number | null;
  last_charge: string | null;
  ctts: number | null;
  count_charge_session: number | null;
  interval_telemetry_S: number | null;
  battery_telemetry?: BatteryTelemetryRedisModel[];
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type StatusTracker = 'ONLINE' | 'OFFLINE';
export type TrackerInfoOutput = {
  id: string;
  imei: string;
  status: StatusTracker;
  serial_number: string;
  firmware?: string | null;
  ip_address?: string | null;
  ev_id: string;
  ev_type_id: number;
  phone_number: string;
  last_online: string;
};
export type TelemetryWithTrackerOutput = {
  id: number;
  time: string;
  latitude: number;
  pre_latitude?: number | null;
  longitude: number;
  pre_longitude?: number | null;
  tracker_imei: string;
  speed: number;
  soh: number;
  soc: number;
  power_reserve: number | null;
  charge_current_A: number | null;
  discharge_current_A: number | null;
  permissible_charge_current: number | null;
  permissible_discharge_current: number | null;
  last_charge: string | null;
  ctts: number | null;
  count_charge_session: number | null;
  interval_telemetry_S: number | null;
  tracker?: TrackerInfoOutput | null;
};
export type TelemetryBaseOutput = {
  id: number;
  time: string;
  latitude: number;
  pre_latitude?: number | null;
  longitude: number;
  pre_longitude?: number | null;
  tracker_imei: string;
  speed: number;
  soh: number;
  soc: number;
  power_reserve: number | null;
  charge_current_A: number | null;
  discharge_current_A: number | null;
  permissible_charge_current: number | null;
  permissible_discharge_current: number | null;
  last_charge: string | null;
  ctts: number | null;
  count_charge_session: number | null;
  interval_telemetry_S: number | null;
};
export const {
  useGetHotStorageApiHotStorageGetQuery,
  useGetTelemetriesReportApiReportGetQuery,
  useGetTelemetryByFilterSrvTelemetriesGetQuery,
  useGetTelemetryByFilterSrvTelemetriesImeiGetQuery,
} = injectedRtkApi;
