import { createSlice } from '@reduxjs/toolkit';
import { StatusTracker } from '@shared/api/services/tracker/api';
import dayjs from 'dayjs';

type State = {
  modelIds: number[];
  statuses: StatusTracker[];
  firmware: string[];
  parks: string[];
  imei?: string;
  range: dayjs.Dayjs[] | null;
};

const initialState: State = {
  modelIds: [],
  statuses: [],
  firmware: [],
  parks: [],
  imei: undefined,
  range: null,
};

const slice = createSlice({
  name: 'trackersFilters',
  initialState,
  reducers: {
    setFilters: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

export const { setFilters } = slice.actions;

export const selectTrackersFilters = (state: RootState) =>
  state.trackersFilters;

export const selectHasFilters = (state: RootState) => {
  const { modelIds, statuses, firmware, imei, range, parks } =
    state.trackersFilters;

  if (
    [modelIds, statuses, firmware, imei, range, parks].some(
      (el) => el !== undefined && el !== null && el?.length > 0
    )
  ) {
    return true;
  }

  return false;
};

export default slice.reducer;
