import { useTrackersData } from '@features/trackers/trackers-table/hooks/useTrackersData';
import { useGetTrackersModelsQuery } from '@shared/api/services/common/enhanced';
import { useGetAllOfTheTrackersQuery } from '@shared/api/services/tracker/enhanced';
import { useMemo } from 'react';

export function useData() {
  const {
    data: trackersModels,
    error: trackersModelsError,
    isLoading: areTrackersModelsLoading,
    isSuccess: areTrackersModelsSuccess,
  } = useGetTrackersModelsQuery();

  const {
    trackersTableData: trackersOutput,
    isLoading: isTrackersLoading,
    isSuccess: isTrackersSuccess,
    error: trackersError,
  } = useTrackersData({});

  const error = [trackersModelsError, trackersError].find(
    (err) => err !== undefined
  );
  const isLoading = areTrackersModelsLoading || isTrackersLoading;

  const isSuccess = areTrackersModelsSuccess && isTrackersSuccess;

  const firmwaresData: string[] = useMemo(() => {
    if (trackersOutput) {
      return Array.from(
        new Set(trackersOutput.map(({ firmware }) => firmware))
      ).filter((item) => item !== undefined && item !== null);
    }

    return [];
  }, [trackersOutput]);

  const trackersImeisData: string[] = useMemo(() => {
    if (trackersOutput) {
      return trackersOutput.map(({ imei }) => imei);
    }

    return [];
  }, [trackersOutput]);

  const parks: string[] = useMemo(() => {
    if (trackersOutput) {
      return Array.from(
        new Set(trackersOutput.map(({ park_name }) => park_name))
      ).filter((item) => item !== undefined && item !== null);
    }

    return [];
  }, [trackersOutput]);

  return {
    isSuccess,
    isLoading,
    error,
    trackersModels,
    trackersImeisData,
    firmwaresData,
    parks,
  };
}
