import { TrackerTableType } from '@entities/tracker/consts/types';
import { StatusTracker } from '@shared/api/services/tracker/api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'; // Плагин для проверки >=
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'; // Плагин для проверки
import { useMemo } from 'react';

type Props = {
  tableData: TrackerTableType[];
  filters: {
    statuses: StatusTracker[];
    firmwares: string[];
    modelIds: number[];
    parks: string[];
    imei?: string;
    range: dayjs.Dayjs[] | null;
  };
};

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const useFilterData = ({
  tableData,
  filters,
}: Props): TrackerTableType[] => {
  const {
    statuses,
    firmwares,
    modelIds,
    parks: parksFilter,
    imei: imeiFilter,
    range: rangeFilter,
  } = filters;

  //  const dateString = '2025-01-21T12:15:57';
  //
  //  // Парсим строку как UTC-время
  //  const formattedDate = dayjs.utc(dateString);
  //
  //  console.log('formattedDate', formattedDate.format()); // Оригинальное время UTC: 2025-01-21T12:15:57+00:00
  //  console.log('toISOString:', formattedDate.toISOString()); // 2025-01-21T12:15:57.000Z

  const filteredData = useMemo(() => {
    return tableData.filter(
      ({ status, firmware, model_id, park_name, imei, last_online }) => {
        const byStatuses =
          statuses.length !== 0
            ? status !== null &&
              status !== undefined &&
              statuses.includes(status)
            : true;
        const byFirmwares =
          firmwares.length !== 0
            ? firmware !== undefined &&
              firmware !== null &&
              firmwares.includes(firmware)
            : true;
        const byModelsIds =
          modelIds.length !== 0
            ? model_id !== undefined &&
              model_id !== null &&
              modelIds.includes(model_id)
            : true;
        const byParkd =
          parksFilter.length !== 0
            ? park_name !== undefined &&
              park_name !== null &&
              parksFilter.includes(park_name)
            : true;
        const byImei = imeiFilter !== undefined ? imeiFilter === imei : true;

        let byLastOnline = true;

        if (rangeFilter) {
          const [dateFrom, dateTo] = rangeFilter;

          const dateToCheck = dayjs(last_online);
          byLastOnline =
            dateToCheck.isSameOrAfter(dateFrom) &&
            dateToCheck.isSameOrBefore(dateTo);
        }

        return (
          byStatuses &&
          byFirmwares &&
          byModelsIds &&
          byParkd &&
          byImei &&
          byLastOnline
        );
      }
    );
  }, [
    tableData,
    statuses,
    firmwares,
    modelIds,
    parksFilter,
    imeiFilter,
    rangeFilter,
  ]);

  return filteredData;
};
