import { TrackerTableType } from '@entities/tracker/consts/types';
import { useParkVehicleData } from '@entities/vehicle/hooks/use-park-vehicle-data';
import { getParkNameByGroupId } from '@entities/vehicle/lib/get-park-name-by-group-id';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useGetAllOfTheTrackersQuery } from '@shared/api/services/tracker/enhanced';
import { useMemo } from 'react';

type Props = {
  pollingInterval?: number;
};

export const useTrackersData = ({
  pollingInterval,
}: Props): {
  trackersTableData: Array<TrackerTableType>;
  isLoading: boolean;
  isSuccess: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
} => {
  const {
    data: trackersOutput,
    isLoading: isTrackersLoading,
    isFetching,
    isSuccess: isTrackersSuccess,
    error: trackersError,
  } = useGetAllOfTheTrackersQuery(undefined, { pollingInterval });

  const {
    parks,
    electricbuses,
    isLoading: isParksOrElectricbusesLoading,
    isSuccess: isParksOrElectricbusesSuccess,
    error: parksOrElectricbusesError,
  } = useParkVehicleData({});

  const trackersTableData = useMemo(() => {
    if (trackersOutput === undefined) return [];

    return trackersOutput.map((tracker) => {
      const groupId = electricbuses?.find(
        ({ id }) => tracker.ev_id === id
      )?.group_id;

      let park_name: string | null = null;

      if (groupId) {
        park_name = getParkNameByGroupId(groupId, parks) ?? null;
      }

      return { ...tracker, park_name };
    });
  }, [trackersOutput, electricbuses, parks]);

  const isLoading = isParksOrElectricbusesLoading || isTrackersLoading;

  const isSuccess = isParksOrElectricbusesSuccess && isTrackersSuccess;

  const error = [trackersError, parksOrElectricbusesError].find(
    (err) => err !== undefined
  );

  return {
    trackersTableData,
    isLoading,
    error,
    isSuccess,
  };
};
