import { useMemo } from 'react';
import { TrackerOutput } from '@shared/api/services/tracker/api';
import { TrackerTableType } from '@entities/tracker/consts/types';

type Props = {
  tableData: TrackerTableType[];
  sortedBy: string | null;
  sortedField: string | null;
};

export const useSortTrackersData = ({
  tableData,
  sortedBy,
  sortedField,
}: Props) => {
  const sortedVehicles = useMemo(() => {
    const clonedTableData = JSON.parse(
      JSON.stringify(tableData)
    ) as TrackerTableType[];

    if (sortedBy === 'asc' && sortedField !== null) {
      switch (sortedField) {
        case 'imei':
          return clonedTableData.sort(
            (a, b) => Number(a.imei) - Number(b.imei)
          );
        case 'status':
          return clonedTableData.sort((a, b) => {
            const prevStatus = a.status ?? '';
            const nextStatus = b.status ?? '';

            if (a.status === 'ONLINE') {
              return 1;
            }

            if (b.status === 'ONLINE') {
              return -1;
            }

            return 0;
          });
        case 'last_online':
          return clonedTableData.sort((a, b) => {
            if (a.last_online === null) return 1; // null всегда внизу
            if (b.last_online === null) return -1; // null всегда внизу

            //@ts-ignore
            return new Date(a.last_online) - new Date(b.last_online);
          });
        case 'park_name':
          return clonedTableData.sort((a, b) => {
            if (a.park_name === null) return 1; // null всегда внизу
            if (b.park_name === null) return -1; // null всегда внизу
            const prevName = a.park_name ?? '';
            const nextName = b.park_name ?? '';
            return prevName.localeCompare(nextName, 'ru-RU');
          });

        default:
          return clonedTableData.sort((a, b) => {
            // @ts-ignore
            if (a[sortedField] === null) return 1; // null всегда внизу
            // @ts-ignore
            if (b[sortedField] === null) return -1; // null всегда внизу
            // @ts-ignore
            return a[sortedField] - b[sortedField];
          });
      }
    }

    if (sortedBy === 'desc' && sortedField !== null) {
      switch (sortedField) {
        case 'imei':
          return clonedTableData.sort(
            (a, b) => Number(b.imei) - Number(a.imei)
          );

        case 'status':
          return clonedTableData.sort((a, b) => {
            if (a.status === 'OFFLINE') {
              return 1;
            }

            if (b.status === 'OFFLINE') {
              return -1;
            }

            return 0;
          });

        case 'last_online':
          return clonedTableData.sort((a, b) => {
            if (a.last_online === null) return 1; // null всегда внизу
            if (b.last_online === null) return -1; // null всегда внизу
            //@ts-ignore
            return new Date(b.last_online) - new Date(a.last_online);
          });
        case 'park_name':
          return clonedTableData.sort((a, b) => {
            if (a.park_name === null) return 1; // null всегда внизу
            if (b.park_name === null) return -1; // null всегда внизу
            const prevName = a.park_name ?? '';
            const nextName = b.park_name ?? '';
            return nextName.localeCompare(prevName, 'ru-RU');
          });
        default:
          return clonedTableData.sort(
            // @ts-ignore
            (a, b) => b[sortedField] - a[sortedField]
          );
      }
    }

    return clonedTableData;
  }, [tableData, sortedField, sortedBy]);

  return sortedVehicles;
};
