import { useAppSelector } from '@app/hooks';
import {
  SortDirection,
  TrackerSortEnum,
} from '@shared/api/services/tracker/api';
import {
  useGetAllOfTheTrackersQuery,
  useGetTrackersQuery,
  useLazyGetTrackersQuery,
} from '@shared/api/services/tracker/enhanced';
import { QUERY_PARAM } from '@shared/consts/server';
import { selectTrackersFilters } from '@shared/redux/slices/tracker/filters';
import { FC, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TrackersTableInstance } from '../table';
import { useGetTrackersModelsQuery } from '@shared/api/services/common/enhanced';
import { RowSelectionState } from '@tanstack/react-table';
import { countPageSize } from '@shared/ui/table/lib/count-page-size';
import { useTrackersData } from '../../hooks/useTrackersData';
import { useFilterData } from '@features/trackers/filters/hooks/use-filter-data';

type Props = {
  rowSelection: RowSelectionState;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
};

export const ConnectedTrackersTable: FC<Props> = ({
  rowSelection,
  setRowSelection,
}) => {
  const [isPaginatingOrFiltration, setIsFiltratingOrPagination] =
    useState(false);

  const [searchParams] = useSearchParams();

  const { statuses, firmware, modelIds, parks, imei, range } = useAppSelector(
    selectTrackersFilters
  );

  // TODO: засунуть в хук обработки данных

  const {
    data: trackersModels,
    error: trackersModelsError,
    isLoading: areTrackersModelsLoading,
    isSuccess: areTrackersModelsSuccess,
  } = useGetTrackersModelsQuery();

  const defaultPageSize = countPageSize();

  const {
    trackersTableData: trackersOutput,
    isLoading,
    error,
    isSuccess,
  } = useTrackersData({ pollingInterval: 5000 });

  const tableData = useMemo(() => {
    if (!trackersOutput) {
      return [];
    }

    return trackersOutput;
  }, [trackersOutput]);

  const filteredData = useFilterData({
    tableData,
    filters: { statuses, firmwares: firmware, modelIds, parks, imei, range },
  });

  if (isLoading || areTrackersModelsLoading) {
    return <div>Идет загрузка....</div>;
  }

  if (error || trackersModelsError) {
    return (
      <>
        <div>Ошибка: не удалось загрузить данные по трекерам</div>
        {error && <div>{JSON.stringify(error)}</div>}
        {trackersModelsError && (
          <div>{JSON.stringify(trackersModelsError)}</div>
        )}
      </>
    );
  }

  if (isSuccess) {
    return (
      <TrackersTableInstance
        tableData={filteredData}
        globalTableData={tableData}
        fetching={isPaginatingOrFiltration && !isLoading}
        count={filteredData?.length ?? 1}
        models={trackersModels}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        defaultPageSize={defaultPageSize}
      />
    );
  }

  return <>Не удалось загрузить данные по трекерам</>;
};
